<template>
  <b-card :bg-variant="accountDetailsBackground">
    <b-card-title class="d-flex align-items-center">
      <span class="mr-auto">Account Details</span>
      <b-button v-if="!editNameMode" variant="link" @click="setEditNameState(true)">
        <font-awesome-icon icon="edit" class="fa-lg"/>
      </b-button>
      <b-button v-if="editNameMode" @click="updateName" variant="outline-primary" class="mr-1">
        <font-awesome-icon icon="check" class="fa-md"/>
      </b-button>
      <b-button v-if="editNameMode" @click="setEditNameState(false)" variant="outline-danger">
        <font-awesome-icon icon="window-close" class="fa-md"/>
      </b-button>
    </b-card-title>
    <font-awesome-icon icon="spinner" class="fa-spin" v-if="busy"/>
    <b-container v-if="currentUser!=null">
      <b-row>
        <b-col v-if="!editNameMode" class="font-weight-bold">{{currentUser.name}}</b-col>
        <b-col v-else>
          <b-input-group id="input-group-editName">
            <b-form-input id="input-name" v-model="currentUserName"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{currentUser.email}}
          <font-awesome-icon
            icon="check-circle"
            class="fa-lg ml-2 text-success"
            v-if="emailVerified"
          />
          <font-awesome-icon v-else icon="window-close" class="fa-lg ml-2 text-danger" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          Signed up:
          <span id="signedUpSpan">{{signedUp}}</span>
        </b-col>
      </b-row>

      <b-tooltip target="signedUpSpan" title="Sign-up Date">{{ signedUpExact }}</b-tooltip>
    </b-container>
  </b-card>
</template>

<script>
import moment from "moment"

export default {
  name: 'ProfileDetails',
  computed: {
    busy: {
      get() {
        return this.$store.state.users.loadingCurrentUser
      }
    },
    currentUser: {
      get() {
        return this.$store.state.users.currentUser
      }
    },
    currentUserName: {
      get() {
        return this.tempUserName
      },
      set(value) {
        this.tempUserName = value
      }
    },
    emailVerified: {
      get() {
        if(this.$store.state.users.currentUser) {
          return this.$store.state.users.currentUser.email_verified
        } else {
          return false
        }
      }
    },
    signedUp: {
      get() {
        if (this.currentUser) {
          var time = moment(this.currentUser.created_at)
          return moment().to(time)
        }
        return ""
      }
    },
    signedUpExact: {
      get() {
        if (this.currentUser) {
          var time = moment(this.currentUser.created_at)
          return time.format("YYYY-MM-DD HH:mm")
        }
        return ""
      }
    },
    accountDetailsBackground: {
      get() {
        if (this.editNameMode) {
          return 'white'
        } else {
          return 'light'
        }
      }
    }
  },
  data() {
    return {
      editNameMode: false,
      error: null,
      tempUserName: "",
    }
  },
  methods: {
    getUserDetails(reload) {
      this.$store.dispatch("users/fetchCurrentUserData", reload)
    },
    setEditNameState(editMode) {
      this.editNameMode = editMode;
      //If we turn edit mode off, get rid of the changes
      if (this.editNameMode == false) {
        this.tempUserName = this.currentUser.name
      }
    },
    async updateName() {
      try {
        await this.$store.dispatch("auth/updateCurrentUserName", this.tempUserName)
        this.getUserDetails(true)
      } catch (err) {
        console.log('Something broke while updating the username...')
        console.log(err)
        this.error = err
      }
    },
  },
  mounted() {
    this.getUserDetails(false)
  },
  watch: {
    currentUser: {
      handler: function(newUser) {
        // console.log('Watch current user')
        // if (newUser.user_metadata) {
          const newName = newUser.name;
          if (this.tempUserName != newName) {
            // console.log('Updating temp user name')
            // console.log(this.tempUserName)
            // console.log(newName)
            this.tempUserName = newName
          } else {
            // console.log('Setting edit mode to false')
            this.editNameMode = false
          }
        // }
      },
      deep: true
    }
  }
}
</script>

<style>
</style>
