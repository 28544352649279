import Vue from 'vue'
import axios from 'axios'

const HTTP = axios.create({
    baseURL: process.env.VUE_APP_API_URI
})
  
// HTTP.interceptors.request.use(
//   config => {
//       const token = localStorage.getItem('access_token');  
//       if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//       }
//     return config;
//   },
//   error => {
//     console.log('axios error..')
//     console.dir(error)
//     return new Promise((ignore, reject) =>  reject(error))
//   }
// );
  
let usersAPI = new Vue({

  methods: {

    getUsersList () {
        return new Promise((resolve, reject) => {
        HTTP.get('private')
            .then(response => {
                resolve(response)
            })
            .catch(e => {
                reject(e)
            })
        })
    },
    addNewUser (user) {
      // console.log('addNewUser')
      return new Promise((resolve, reject) => {
        HTTP.post('addUser', user)
          .then(response => {
            // console.log('addUser response')
            resolve(response)
          })
          .catch(e => {
            console.log('addUser error response')
            console.log(e.response)
            reject(e)
          })
      })
    },
  resendVerificationEmail (email) {
    // console.log('resendEmail')
    return new Promise((resolve, reject) => {
    HTTP.post('verificationEmail', email)
        .then(response => {
          // console.log('resendEmail response')
          resolve(response)
        })
        .catch(e => {
          console.log('resendEmail error response')
          console.log(e.response)
          reject(e)
        })
    })
  },
  sendPasswordResetEmail (email) {
    // console.log('sendPasswordResetEmail')
    return new Promise((resolve, reject) => {
    HTTP.post('passwordEmail', email)
        .then(response => {
          // console.log('sendPasswordResetEmail response')
          resolve(response)
        })
        .catch(e => {
          console.log('sendPasswordResetEmail error response')
          console.log(e.response)
          reject(e)
        })
    })
  }
}
})

export default usersAPI
