<template>
  <div id="account-mgmt-div">
    <b-container>
      <b-row class="my-4">
        <b-col cols="12" md="7" lg="8">
          <profile-details />
        </b-col>

        <b-col cols="4" md="5" lg="4">
          <b-card header="Links" border-variant="secondary" header-class="font-weight-bold text-center h5"
            header-border-variant="secondary" header-text-variant="black">
            <ul>
              <li>
                <b-link @click="confirmPasswordChange">Change My Password</b-link>
              </li>

              <li v-if="!emailVerified">
                <b-link @click="sendVerificationEmail">
                  Resend Verification Email
                </b-link>
              </li>

              <li>
                Contact Us:
                <b-link href="mailto:support@safearth.com">
                  support@safearth.com
                </b-link>
              </li>
            </ul>
          </b-card>
          <!-- <b-card title="Legals" border-variant="white">
            <ul>
              <li>
                <b-link href="https://www.safearth.com/terms-of-service/">
                  Terms of Service
                </b-link>
              </li>
              <li>
                <b-link href="https://www.safearth.com/fair-use-policy/">
                  Fair Use Policy
                </b-link>
              </li>
              <li>
                <b-link href="https://www.safearth.com/privacy-policy/">
                  Privacy Policy
                </b-link>
              </li>
            </ul>
          </b-card> -->
        </b-col>
      </b-row>
    </b-container>

    <b-modal ref="errorModal" title="Error" ok-only>
      <p class="my-4">{{ errorMessage }}</p>
    </b-modal>

    <b-modal ref="emailSentModal" title="Email Sent" ok-only>
      <p class="my-4">
        A {{ sentEmailType }} has been sent to <strong>{{ email }}</strong>
      </p>
      <p>Please check your inbox, and follow the instructions in the email.</p>
      <p>
        Contact us using
        <b-link href="mailto:support@safearth.com">support@safearth.com</b-link>
        if you have any trouble.
      </p>
    </b-modal>

    <b-modal ref="passwordChangeConfirmModal" title="Do you want to change your password?" ok-title="Send Email"
      @ok="sendPasswordEmail">
      To make sure it's really you asking for this change, we'll send you an
      email with a link you can use to change your password.
      <!-- <p class="mt-2">Do you want to want to continue?</p> -->
    </b-modal>
  </div>
</template>

<script>
import usersAPI from "@/auth/users.js";
import ProfileDetails from "@/components/ProfileDetails.vue";

export default {
  name: "UserAccountManagement",
  components: {
    ProfileDetails,
  },
  data: function () {
    return {
      errorMessage: "",
      sentEmailType: "",
    };
  },
  mounted: function () {
    this.getUserDetails(false);
  },
  computed: {
    email: {
      get() {
        if (this.$store.state.users.currentUser) {
          return this.$store.state.users.currentUser.email;
        }
        return "";
      },
    },
    emailVerified: {
      get() {
        if (this.$store.state.users.currentUser) {
          return this.$store.state.users.currentUser.email_verified;
        } else {
          return false;
        }
      },
    },
  },
  methods: {
    confirmPasswordChange() {
      this.$refs.passwordChangeConfirmModal.show();
    },
    getUserDetails(reload) {
      this.$store.dispatch("users/fetchCurrentUserData", reload);
    },
    sendPasswordEmail() {
      usersAPI
        .sendPasswordResetEmail(this.email)
        .then(() => {
          // console.log(response)
          this.sentEmailType = "password reset link";
          this.$refs.emailSentModal.show();
        })
        .catch((err) => {
          // console.log('got an error in onSubmit')
          console.dir(err.response.data.message);
          this.errorMessage = err.response.data.message;
          this.$refs["errorModal"].show();
        });
    },
    sendVerificationEmail() {
      usersAPI
        .resendVerificationEmail(this.email)
        .then(() => {
          // console.log(response)
          this.sentEmailType = "verification link";
          this.$refs.emailSentModal.show();
        })
        .catch((err) => {
          // console.log('got an error in onSubmit')
          console.dir(err.response.data.message);
          this.errorMessage = err.response.data.message;
          this.$refs["errorModal"].show();
        });
    },
  },
};
</script>

<style></style>
